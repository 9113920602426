/**
 * This hook is used to display a toast notification based on a message in the url.
 * It will scan a url for the message key (generated by redirects from the API)
 * If it matches one of these messages, it will pop a toast, and remove the query param from the url
 */
import { useSearchParams } from "@remix-run/react";
import { useCallback, useEffect } from "react";
import { z } from "zod";
import { useToaster } from "~/features/toasts/toast.machine";

export const MESSAGE_KEY = "notif";
export const messageSchema = z
  .enum([
    "SUBSCRIBE.DOWNGRADE_TO_FREE_SUCCESS",
    "SUBSCRIBE.DOWNGRADE_TO_FREE_FAILURE",
    "SUBSCRIBE.NO_PLAN",
    "SUBSCRIBE.NO_SUBSCRIPTION",
    "SUBSCRIBE.SUCCESS",
    "SUBSCRIBE.TRIAL_SUCCESS",
    "SUBSCRIBE.INELIGIBLE",
    "SUBSCRIBE.PAYMENT_METHOD_UPDATE_SUCCESS",
    "SUBSCRIBE.PROVISION_FAILED",
    "UNKNOWN",
  ])
  .optional()
  .catch("UNKNOWN");

export type Message = z.infer<typeof messageSchema>;

/**
 * Listener setup at the root of the app to scan for the message key in the url
 * This will _only_ remove the message key if the toast was successfully displayed
 */
export function useNotifyFromUrlMessage() {
  const [params] = useSearchParams();
  const message = params.get(MESSAGE_KEY);
  const { notify } = useToaster();

  const removeUrlParam = useCallback(() => {
    params.delete(MESSAGE_KEY);
    const newUrl = [window.location.pathname, params].filter(Boolean).join("?");
    const finalUrl = params.toString() ? newUrl : window.location.pathname;
    // this allows us to replace the current history state with the new url
    window.history.replaceState({ ...window.history.state, idx: window.history.state.idx ?? 0 }, "", finalUrl);
  }, [params]);

  useEffect(() => {
    if (!message) return;
    const parsedMessage = messageSchema.parse(message);
    switch (parsedMessage) {
      case "SUBSCRIBE.DOWNGRADE_TO_FREE_SUCCESS":
        notify?.({
          title: "Success",
          description: "Downgrade successful. This change will take effect on your next billing cycle.",
          type: "success",
        });
        break;
      case "SUBSCRIBE.DOWNGRADE_TO_FREE_FAILURE":
        notify?.({
          title: "Error",
          description: "Unable to downgrade to free plan. Please try again later.",
          type: "error",
        });
        break;
      case "SUBSCRIBE.SUCCESS":
        notify?.({
          title: "Success",
          description: "Subscription successful.",
          type: "success",
        });
        break;
      case "SUBSCRIBE.TRIAL_SUCCESS":
        notify?.({
          title: "Success",
          description: "Trial subscription successful. Your trial will end in 14 days.",
          type: "success",
        });
        break;
      case "SUBSCRIBE.NO_PLAN":
      case "SUBSCRIBE.INELIGIBLE":
        notify?.({
          title: "Error",
          description: "Unable to find your plan. Please try again later.",
          type: "error",
        });
        break;
      case "SUBSCRIBE.PAYMENT_METHOD_UPDATE_SUCCESS":
        notify?.({
          title: "Success",
          description: "Payment method updated successfully.",
          type: "success",
        });
        break;
      case "SUBSCRIBE.PROVISION_FAILED":
        notify?.({
          title: "Error",
          description: "Unable to provision your subscription. Please try again later.",
          type: "error",
        });
        break;
    }
    if (notify) {
      removeUrlParam();
    }
  }, [message, notify, removeUrlParam]);
}
