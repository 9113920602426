import { Modal, Text } from "@fscrypto/ui";
import { useCopyWorkItem } from "./actor";
import { TeamSelector } from "./components/team-selector";
import FileExplorer from "./components/file-explorer";
import Footer from "./components/footer";
import Header from "./components/header";
import Empty from "./components/empty";

export const CopyWorkItem = () => {
  const {
    isOpen,
    closeModal,
    isReady,
    workItems,
    isLoading,
    isInitialLoading,
    setSelectedCollectionId,
    selectedCollection,
    setCurrentCollectionId,
    currentCollection,
    currentTypename,
    currentName,
    selectedContext,
    copyWorkItem,
    isCopying,
  } = useCopyWorkItem();
  if (!isReady) return null;
  return (
    <div>
      <Modal open={isOpen} className="sm:w-[600px] sm:max-w-[600px]" setOpen={() => {}}>
        <div onClick={(e) => e.stopPropagation()} onDoubleClick={(e) => e.stopPropagation()}>
          <Header
            typename={currentTypename}
            name={currentName}
            collectionName={null}
            onClose={() => {
              closeModal();
            }}
            isLoading={isInitialLoading}
          />
          <TeamSelector />
          <div className="ml-2 flex w-full px-2">
            <Text color="muted" size="xs">
              All members of the selected account will have access to this file
            </Text>
          </div>
          {selectedContext ? (
            <>
              <FileExplorer
                workItems={workItems}
                selectedCollection={selectedCollection}
                currentCollection={currentCollection}
                setCurrentCollectionId={setCurrentCollectionId}
                setSelectedCollectionId={setSelectedCollectionId}
                isLoading={isLoading}
              />
              <Footer
                currentTypename={currentTypename}
                context={selectedContext}
                copyWorkItem={copyWorkItem}
                selectedCollection={selectedCollection}
                currentCollection={currentCollection}
                isMoveDisabled={isLoading}
                isLoading={isCopying}
              />
            </>
          ) : (
            <Empty />
          )}
        </div>
      </Modal>
    </div>
  );
};
